import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import '../styles/print.sass'
import MiniMenuPress from '../components/MiniMenuPress'

const PressPosts = (props) => {
  const { data } = props
  const { edges: pressPosts } = data.allMarkdownRemark

  const interviewPosts = pressPosts.filter(
    ({ node: post }) => post.frontmatter.type === 'Interview'
  )

  const printPosts = pressPosts.filter(
    ({ node: post }) => post.frontmatter.type === 'Print'
  )

  const podcastPosts = pressPosts.filter(
    ({ node: post }) => post.frontmatter.type === 'Podcast'
  )

  const articlePosts = pressPosts.filter(
    ({ node: post }) => post.frontmatter.type === 'Article'
  )

  return (
    <section className="section press">
      <div className="container">
        <div className="content">
          <h1 className="text-center">IN THE MEDIA</h1>
          <MiniMenuPress />
        </div>
        <div className="print-container">
          <div className="print-grid">
            <h2 id="interviews">Interviews</h2>
            <div className="container-flex">
              {interviewPosts.map(({ node: post }) => (
                <div className="print-item">
                  <a
                    href={post.frontmatter.link ? post.frontmatter.link : '#'}
                    target="_blank"
                  >
                    <img
                      className="interview-img"
                      src={
                        post.frontmatter.pressimage.childImageSharp.fluid.src
                      }
                      alt={post.frontmatter.title}
                    />
                    <div className="print-header">{post.frontmatter.title}</div>
                  </a>
                  {post.frontmatter.date}
                </div>
              ))}
            </div>
          </div>

          {articlePosts.length > 0 && (<div className="print-grid">
            <h2 id="articles">Articles</h2>
            <div className="container-flex">
              {articlePosts.map(({ node: post }) => (
                <div className="print-item">
                  <a
                    href={post.frontmatter.link ? post.frontmatter.link : '#'}
                    target="_blank"
                  >
                    <img
                      className="interview-img"
                      src={
                        post.frontmatter.pressimage.childImageSharp.fluid.src
                      }
                      alt={post.frontmatter.title}
                    />
                    <div className="print-header">{post.frontmatter.title}</div>
                  </a>
                  {post.frontmatter.date}
                </div>
              ))}
            </div>
          </div>)}

          <div className="print-grid">
            <h2 id="podcasts">Podcasts</h2>
            <div className="container-flex">
              {podcastPosts.map(({ node: post, index }) => (
                <div key={index} className="print-item">
                  <a
                    href={post.frontmatter.link ? post.frontmatter.link : '#'}
                    target="_blank"
                  >
                    <img
                      className="interview-img print-img"
                      src={
                        post.frontmatter.pressimage.childImageSharp.fluid.src
                      }
                      alt={post.frontmatter.title}
                    />
                    <div className="print-header">{post.frontmatter.title}</div>
                  </a>
                  <div className="print-date">{post.frontmatter.date}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="print-grid">
            <h2 id="print">Print Reviews</h2>
            <div className="container-flex">
              {printPosts.map(({ node: post }) => (
                <div className="print-item">
                  <img
                    className="interview-img print-img"
                    src={post.frontmatter.pressimage.childImageSharp.fluid.src}
                    alt={post.frontmatter.title}
                  />
                  <div className="print-header">{post.frontmatter.title}</div>
                  <div className="print-date">{post.frontmatter.date}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PressPosts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query PressQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "press-post" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                templateKey
                date(formatString: "MM.DD.YYYY")
                link
                type
                pressimage {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <PressPosts data={data} />}
  />
)
