import React from 'react'
import { Link } from 'gatsby'
import '../styles/subnavbar.sass'

const MiniMenuPress = () => {
  return (
    <nav
      className="subnavbar-hero minimenu-hero"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        className='mininav-hero'
      >
        <Link className="subnav-item" to="#interviews">
          INTERVIEWS
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#articles">
          ARTICLES
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#podcasts">
          PODCASTS
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#print">
          PRINT REVIEWS
        </Link>

      </div>
    </nav>
  )
}

export default MiniMenuPress
