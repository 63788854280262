import React from 'react'
import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import '../../styles/print.sass'
import Footer from '../../components/Footer'
import PressPosts from '../../components/PressPosts'

export const Index = () => {
  return (
    <>
      <Navbar showSub={false} showMenu={true} bold />
      <Layout>
        <PressPosts />
        <Footer />
      </Layout>
    </>
  )
}

export default Index
